import React from 'react'

import AboveTheFold from './AboveTheFold'
import Benefits from './Benefits'

export default () => {
  const joinLink =
    'http://app.impact.com/campaign-campaign-info-v2/Finn.brand?io=qwSovASDT26bpSPMsEiPtAq10OKz2y1%2B83VAMk1ndCsPpq1ZUqwzH094SpUPlNTR'
  return (
    <>
      <AboveTheFold joinLink={joinLink} />
      <Benefits joinLink={joinLink} />
    </>
  )
}
