import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { H4, LargeP } from 'src/components/shared/text'
import { PrimaryButton } from 'src/components/shared/buttons'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Section = styled.section`
  position: relative;

  background-color: ${colors.pink};

  display: grid;
  place-items: center;

  ${media.desktop`
    min-height: 800px;
  `}
`

const Grid = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  display: grid;
  align-items: center;

  padding: 33px 24px 370px 24px;

  ${media.tablet`
    justify-items: start;
    grid-template-columns: 1.1fr 1fr;
    padding: 80px 0 120px 30px;
  `}
  ${media.desktop`
    padding: 82px 0 82px 90px;
  `}
`

const TextContainer = styled.div`
  width: 100%;

  display: grid;
  justify-items: center;
  align-items: center;
  grid-row-gap: 25px;

  text-align: center;

  ${media.tablet`
    max-width: 550px;
    justify-items: start;
    text-align: left;
  `}
  ${media.desktop`
    max-width: 640px;
    grid-row-gap: 52px;
  `}
`

const Logo = styled.div`
  cursor: pointer;
  transition: 100ms transform ease-out;

  ${media.mobile`
    width: 123px;
    margin-bottom: 20px;
  `}
  ${media.desktop`
    width: 175px;
    margin-bottom: 30px;
  `}

  &:hover {
    transform: scale(1.03) rotate(-4deg) translate(-6px, -3px);
  }
`

const StyledH4 = styled(H4)`
  ${media.mobile`
    font-size: 36px;
    line-height: 44px;
  `}
  ${media.tablet`
    font-size: 40px;
    line-height: 50px;
  `}
  ${media.desktop`
    font-size: 64px;
    line-height: 74px;
  `}
`

const LifestyleImg = styled.div`
  position: absolute;
  right: 0;

  ${media.mobile`
    width: 100vw;
    bottom: 10px;
    left: 0;
  `}
  ${media.tablet`
    width: 48vw;
    transform: translate(0, -50%);
    left: auto;
    bottom: auto;
    top: 55%;
    right: 0;
  `}
  ${media.desktop`
    width: 45vw;
    max-width: 750px;
    top: 50%;
  `}
`

const query = graphql`
  query {
    logo: file(relativePath: { regex: "/logos/logo-navy.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
    lifestyle: file(relativePath: { regex: "/affiliate/lifestyle.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    }
  }
`

const AboveTheFold = ({ joinLink }: { joinLink: string }) => {
  const { lifestyle, logo } = useStaticQuery(query)

  const clickLogo = () => {
    navigate('/')
  }

  return (
    <Section>
      <Grid>
        <TextContainer>
          <Logo onClick={clickLogo}>
            <GatsbyImage
              image={getImage(logo)}
              alt="Finn logo"
              style={{ height: '100%', width: '100%' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          </Logo>
          <StyledH4>Obsessed with your dog? Share the love.</StyledH4>
          <LargeP>
            Our <b>Affiliate Program</b> allows you to promote Finn products, earn commissions, and
            be a champion for dog wellness at the same time.
          </LargeP>
          <a href={joinLink} rel="noopener noreferrer">
            <PrimaryButton copy="Join Now" arrow />
          </a>
        </TextContainer>
        <div />
      </Grid>
      <LifestyleImg>
        <GatsbyImage
          image={getImage(lifestyle)}
          alt="Woman with dog"
          style={{ height: '100%', width: '100%' }}
          imgStyle={{ objectFit: 'contain' }}
        />
      </LifestyleImg>
    </Section>
  )
}

export default AboveTheFold
