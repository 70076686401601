import React from 'react'
import styled, { keyframes } from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { H4, P } from 'src/components/shared/text'
import { PrimaryButton } from 'src/components/shared/buttons'
import { colors } from 'src/styles/variables'
import { media } from 'src/styles/util'

const Section = styled.section`
  position: relative;
  background-color: ${colors.orange};

  display: grid;
  place-items: center;

  text-align: center;
  padding: 41px 25px 208px;

  z-index: 100010 !important;

  ${media.tablet`
    padding: 77px 30px 52px;
  `}
  ${media.desktop`
    text-align: left;
    padding: 77px 150px 52px;
  `}
`

const StyledH4 = styled(H4)`
  ${media.mobile`
    font-size: 36px;
    line-height: 46px;
  `}
  ${media.tablet`
    font-size: 58px;
    line-height: 68px;
    max-width: 500px;
  `}
  ${media.desktop`
    font-size: 64px;
    line-height: 74px;
    max-width: none;
  `}
`

const Grid = styled.div`
  width: 100%;

  display: grid;
  align-items: center;

  ${media.mobile`
    grid-template-columns: repeat(2, 1fr);
    margin: 40px auto 55px auto;
    grid-gap: 50px 40px;
  `}
  ${media.tablet`
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    margin: 72px auto;
  `}
  ${media.desktop`
    position: relative;
    max-width: 1050px;
    grid-gap: 70px 50px;
  `}
`

const IconGroup = styled.div`
  height: 100%;
  width: 100%;

  display: grid;
  justify-items: center;
  grid-template-rows: min-content 1fr;
  grid-row-gap: 25px;

  text-align: center;

  ${media.tablet`
    white-space: pre-line;
    grid-row-gap: 30px;
  `}
`

const IconP = styled(P)`
  align-self: center;

  ${media.mobile`
    font-size: 14px;
    line-height: 24px;
  `}
  ${media.tablet`
    font-size: 18px;
    line-height: 28px;
  `}
`

const IconImgWrapper = styled.div`
  ${media.mobile`
    width: 108px;
    height: 108px;
  `}
  ${media.tablet`
    width: 142px;
    height: 142px;
  `}
`

const ContactP = styled(P)`
  text-align: center;

  margin-top: 46px;
  border-bottom: 1px solid ${colors.orange};

  & a {
    font-weight: 600;

    &:hover {
      color: ${colors.navy} !important;
      border-bottom: 1px solid ${colors.navy};
    }
  }
`

const rotate = keyframes`
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

const AffiliateBadge = styled.div`
  animation: ${rotate} 20s linear infinite;

  position: absolute;

  ${media.mobile`
    width: 117px;

    bottom: 34px;
    left: calc(50%-67px);
  `}
  ${media.tablet`
    width: 110px;
    bottom: auto;
    left: auto;

    top: 90px;
    right: 30px;
  `}
  ${media.desktop`
    width: 137px;

    top: -180px;
    right: -100px;
  `}
`

const query = graphql`
  query {
    affiliateBadge: file(relativePath: { regex: "/affiliate/affiliate-badge.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 150)
      }
    }
    icon1: file(relativePath: { regex: "/affiliate/icon-1.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 150)
      }
    }
    icon2: file(relativePath: { regex: "/affiliate/icon-2.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 150)
      }
    }
    icon3: file(relativePath: { regex: "/affiliate/icon-3.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 150)
      }
    }
    icon4: file(relativePath: { regex: "/affiliate/icon-4.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 150)
      }
    }
    icon5: file(relativePath: { regex: "/affiliate/icon-5.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 150)
      }
    }
    icon6: file(relativePath: { regex: "/affiliate/icon-6.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 150)
      }
    }
  }
`

const Icon = ({ img, alt }) => (
  <IconImgWrapper>
    <GatsbyImage
      image={img}
      style={{ height: '100%', width: '100%' }}
      imgStyle={{ objectFit: 'contain' }}
      alt={alt}
    />
  </IconImgWrapper>
)

const Benefits = ({ joinLink }: { joinLink: string }) => {
  const { affiliateBadge, icon1, icon2, icon3, icon4, icon5, icon6 } = useStaticQuery(query)
  const icons = [
    {
      img: getImage(icon1),
      copy: 'Stay updated on upcoming \npromotions',
      alt: 'A circle icon with a percentage tag in the middle'
    },
    {
      img: getImage(icon2),
      copy: 'Absolutely \nfree to join',
      alt: 'A circle icon with a free tag in the middle'
    },
    {
      img: getImage(icon3),
      copy: 'Earn commission on \nevery valid sale you drive',
      alt: 'A circle icon with dollar bills in the middle'
    },
    {
      img: getImage(icon4),
      copy: 'Exclusive affiliate offers',
      alt: 'A circle icon with animals in the middle'
    },
    {
      img: getImage(icon5),
      copy: 'Verified 3rd party tracking and \npayment by Impact Radius',
      alt: 'A circle icon with a shield and checkmark in the middle'
    },
    {
      img: getImage(icon6),
      copy: 'Hassle free - we handle \norders, shipping and payments',
      alt: 'A circle icon with a check board in the middle '
    }
  ]

  return (
    <Section>
      <StyledH4>Benefits of the Program</StyledH4>
      <Grid>
        <AffiliateBadge>
          <GatsbyImage
            image={getImage(affiliateBadge)}
            alt="A Finn dog logo representing the Finn affiliate program"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'contain' }}
          />
        </AffiliateBadge>
        {icons.map((icon) => (
          <IconGroup>
            <Icon img={icon.img} alt={icon.alt} />
            <IconP>{icon.copy}</IconP>
          </IconGroup>
        ))}
      </Grid>
      <a href={joinLink} rel="noopener noreferrer">
        <PrimaryButton copy="Join Now" arrow />
      </a>
      <ContactP>
        Questions about the program?
        <br />
        Email us at{' '}
        <a href="mailto:affiliate@petfinn.com" target="_blank" rel="noopener noreferrer">
          affiliate@petfinn.com
        </a>
      </ContactP>
    </Section>
  )
}

export default Benefits
